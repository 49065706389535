<template>
  <div class="readme-article">
    <div id="学生"></div>
    <h1 id="资料查询">
      一、资料查询
    </h1>
    <h2 id="学生资料查询">1.学生资料查询</h2>
    <h3 id="学生资料查询列表">1.1学生资料查询列表</h3>
    <p>查看学生列表，展示学生基本信息，可点击操作按钮查看详情以及回访登记。</p>
    <p>
      <img src="@/assets/img/student/1-1.png">
    </p>
    <h3 id="学生资料详情">1.2学生资料详情</h3>
    <p>学生详情展示，管理员可编辑学生信息。</p>
    <p>
      <img src="@/assets/img/student/1-2.png">
    </p>
    <h3 id="学生回访记录">1.3学生回访记录</h3>
    <p>查看学生回访记录,添加回访记录。</p>
    <p>
      <img src="@/assets/img/student/1-3.png">
    </p>
    <h2 id="添加学生资料">2.添加学生资料</h2>
    <p>管理员可手动添加学生，完善学生信息资料。</p>
    <p>
      <img src="@/assets/img/student/1-4.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student1-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>